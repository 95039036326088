import { createAsyncThunk } from "@reduxjs/toolkit";
import { PropertyType, SelectedPropertyType } from ".";
import { has } from "lodash";
// import { ModelState } from ".";

export const fetchCsvThunk = createAsyncThunk("csv/fetch", fetchCsv);

export const setDbIdThunk = createAsyncThunk("model/getObjectTree", setDbId);

async function fetchCsv(path: string) {
  const response = await fetch(path);
  const reader = response.body?.getReader();
  const decoder = new TextDecoder("utf-8");
  if (!reader) throw new Error("No reader found");
  const result = await reader.read();
  return decoder.decode(result.value);
}

type Data = {
  name: string | number;
  dbId: number;
  children?: Data[];
  fragIds?: number[];
};

type ResultData = {
  id: string;
  dbId: number;
};

// function getIdDbId(data: Data) {
//   const { name, dbId, children } = data;
//   if (children) {
//     children.map((subData) => getIdDbId(subData));
//   }
//   const stringName = String(name);
//   const matches = stringName.match(/\((.*?\d+)\)/);
//   if (matches) {
//     // resultData.push({ id: matches[1], dbId });
//     return { id: matches[1], dbId };
//   }
// }

export function* getIdDbIdGenerator(data: Data): Generator<ResultData> {
  const { name, dbId, children } = data;
  if (children) {
    for (const child of children) {
      yield* getIdDbIdGenerator(child);
    }
  }
  const stringName = String(name);
  const matches = stringName.match(/\((.*?\d+)\)/);
  if (matches !== null) {
    yield { id: matches[1], dbId };
  }
}

export function setDbId(args: {
  properties: PropertyType[];
  model: Autodesk.Viewing.Model;
}): Promise<SelectedPropertyType[]> {
  const { properties, model } = args;
  return new Promise((resolve, reject) => {
    model.getObjectTree(async (trees) => {
      const hasDbId = has(trees, "dbId");
      if (!hasDbId) {
        const rawString = localStorage.getItem("dbProperties");
        if (!rawString) return;
        const dbProperties = JSON.parse(rawString) as SelectedPropertyType[];
        return resolve(dbProperties);
      }

      const dbProperties: SelectedPropertyType[] = [];
      for await (const tree of getIdDbIdGenerator(trees as unknown as Data)) {
        const result = properties.find((property) => property.id === tree.id);
        if (result) {
          dbProperties.push({ ...result, dbId: tree.dbId });
        }
      }
      localStorage.setItem("dbProperties", JSON.stringify(dbProperties));
      return resolve(dbProperties);
    }, reject);
  });
}
