// https://stackoverflow.com/questions/64880934/metallic-materials-not-working-in-forge-model-viewer

export const setMaterial = async (
  viewer: Autodesk.Viewing.Viewer3D,
  model: Autodesk.Viewing.Model,
  dbIds: number[],
  image: string,
) => {
  const selectedList = viewer.getAggregateSelection();
  const materialMgr = viewer.impl.getMaterials();
  const loader = (image: string): Promise<THREE.Texture> =>
    new Promise((resolve, reject) => {
      return new THREE.TextureLoader().load(image, (texture) => {
        resolve(texture);
      });
    });
  const texture = await loader(image);
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  const material = new THREE.MeshBasicMaterial({
    side: THREE.DoubleSide,
    // flatShading: true,
    // @ts-ignore
    map: texture,
    // skinning: true,
    morphTargets: true,
    // wireframe: true,
  });

  materialMgr.addMaterial("textureMaterial", material, true);
  for (const dbId of dbIds) {
    for (let currentGroup of selectedList) {
      // @ts-ignore
      model.unconsolidate(); // gltf 는 unconsolidate 을 하지 않아도 변화가 가능하지만 nwc 나 revit 모델들은 unconsolidate 을 하지 않으면 변화가 없다.
      const tree = currentGroup.model.getInstanceTree();
      const frags = currentGroup.model.getFragmentList();
      tree.enumNodeFragments(
        dbId,
        (fragId: number) => {
          // @ts-ignore
          frags.setMaterial(fragId, material);
          // @ts-ignore
          const gotMaterial = frags.getMaterial();
          console.log({ gotMaterial });
        },
        true,
      );
    }
  }
};
