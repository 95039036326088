import React from "react";
import { Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ForgeViewer, ThreeViewerContainer } from "./views";
import { RootProvider } from "./contexts";
import { Layout } from "components/Layout";

function App() {
  return (
    <RootProvider>
      <Layout>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<ForgeViewer />} />
          <Route path="/three" element={<ThreeViewerContainer />} />
        </Routes>
      </Layout>
    </RootProvider>
  );
}

export default App;
