import * as React from "react";
import { SVGProps } from "react";

export const Builderhub = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="131"
    height="18"
    viewBox="0 0 131 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.2325 0H2.7675C1.23905 0 0 1.23905 0 2.7675V15.2325C0 16.7609 1.23905 18 2.7675 18H15.2325C16.7609 18 18 16.7609 18 15.2325V2.7675C18 1.23905 16.7609 0 15.2325 0Z"
      fill="#6649E2"
    />
    <path
      d="M5.90914 4.38086H4.05414C3.91745 4.38086 3.80664 4.49167 3.80664 4.62836V13.3759C3.80664 13.5125 3.91745 13.6234 4.05414 13.6234H5.90914C6.04583 13.6234 6.15664 13.5125 6.15664 13.3759V4.62836C6.15664 4.49167 6.04583 4.38086 5.90914 4.38086Z"
      fill="white"
    />
    <path
      d="M14.8127 11.9689H13.1477C13.1902 11.9689 13.2977 11.8164 13.3277 11.7839C13.3802 11.7264 13.4252 11.6639 13.4677 11.5964C13.8677 10.9739 13.8952 10.1689 13.6502 9.48641C13.3802 8.73391 12.7802 8.25891 12.0177 8.05141C12.0627 8.06391 12.1727 7.99891 12.2127 7.97891C12.8477 7.65141 12.9327 6.75641 12.7852 6.13891C12.7277 5.89891 12.6302 5.66641 12.4927 5.46141C12.2577 5.11641 11.9227 4.84891 11.4852 4.66141C11.0477 4.47391 10.5227 4.37891 9.91266 4.37891H7.03266C6.89766 4.37891 6.78516 4.48891 6.78516 4.62641V5.79141C6.78516 5.92641 6.89516 6.03891 7.03266 6.03891H9.28516C9.67766 6.03891 9.98766 6.12891 10.2127 6.31141C10.4377 6.49391 10.5527 6.74391 10.5527 7.06391C10.5527 7.06391 10.5752 7.43891 10.2302 7.71891C9.99266 7.91141 9.58266 7.91141 9.11266 7.91141H7.03516C6.90016 7.91141 6.78766 8.02141 6.78766 8.15891V9.15141C6.78766 9.28641 6.89766 9.39891 7.03516 9.39891H9.73016C10.0052 9.39891 10.2902 9.42391 10.5527 9.52391C10.8427 9.63641 11.1577 9.90141 11.2477 10.2089C11.3902 10.6914 11.3402 11.3364 10.9127 11.6639C10.6552 11.8614 10.2852 11.9614 9.80266 11.9614H7.03766C6.90266 11.9614 6.79016 12.0714 6.79016 12.2089V13.3739C6.79016 13.5089 6.90016 13.6214 7.03766 13.6214H14.8227C14.9577 13.6214 15.0702 13.5114 15.0702 13.3739V12.2139C15.0702 12.0789 14.9602 11.9664 14.8227 11.9664L14.8127 11.9689Z"
      fill="white"
    />
    <path
      d="M24 3H26.5919V14.7983H24V3ZM25.296 12.9585H28.8217C29.5602 12.9585 30.127 12.8151 30.5198 12.5283C30.915 12.2415 31.1125 11.8269 31.1125 11.2824V11.2667C31.1125 10.9059 31.0232 10.6034 30.842 10.3546C30.6609 10.1059 30.3928 9.91989 30.0353 9.7944C29.6778 9.66891 29.2356 9.60616 28.7088 9.60616H25.2936V7.92997H28.4006C29.1321 7.92997 29.6896 7.79104 30.0706 7.51316C30.4516 7.23529 30.6421 6.8409 30.6421 6.32773V6.31204C30.6421 5.8437 30.4728 5.48067 30.1364 5.22521C29.7977 4.96975 29.3273 4.84202 28.7205 4.84202H25.296V3H29.3885C30.1717 3 30.8468 3.12101 31.4136 3.36078C31.9804 3.60056 32.4179 3.94342 32.726 4.38711C33.0341 4.83081 33.1894 5.35518 33.1894 5.96022V5.97591C33.1894 6.41288 33.0859 6.814 32.8765 7.18151C32.6672 7.54902 32.3826 7.85378 32.0228 8.09804C31.6629 8.34005 31.2536 8.49244 30.795 8.5507V8.6C31.3736 8.63361 31.884 8.77479 32.3309 9.03025C32.7778 9.28347 33.1282 9.61512 33.3822 10.0274C33.6363 10.4398 33.7633 10.9036 33.7633 11.4213V11.437C33.7633 12.1227 33.5892 12.7188 33.2435 13.2185C32.8977 13.7204 32.4061 14.1081 31.7687 14.3837C31.1313 14.6594 30.3693 14.7961 29.4826 14.7961H25.296V12.9563V12.9585Z"
      fill="#605E6B"
    />
    <path
      d="M37.6769 14.4577C36.8984 14.0947 36.2986 13.5838 35.8753 12.9249C35.4519 12.2661 35.2402 11.4997 35.2402 10.628V3H37.8322V10.4062C37.8322 10.9126 37.9333 11.3541 38.1379 11.7261C38.3402 12.1003 38.6366 12.3894 39.0223 12.5978C39.408 12.8039 39.8784 12.9092 40.4335 12.9092C40.9886 12.9092 41.459 12.8062 41.8447 12.5978C42.2305 12.3916 42.5245 12.1003 42.7244 11.7261C42.9243 11.3518 43.0254 10.9126 43.0254 10.4062V3H45.6174V10.628C45.6174 11.4997 45.408 12.2639 44.987 12.9204C44.566 13.577 43.9686 14.088 43.1924 14.4532C42.4163 14.8185 41.4966 15 40.4335 15C39.3704 15 38.4578 14.8185 37.6793 14.4555L37.6769 14.4577Z"
      fill="#605E6B"
    />
    <path d="M47.6719 3H50.2638V14.7983H47.6719V3Z" fill="#605E6B" />
    <path
      d="M52.3262 3H54.9181V12.7613H60.3325V14.7961H52.3262V3Z"
      fill="#605E6B"
    />
    <path
      d="M61.8672 3H66.5948C67.8366 3 68.895 3.22633 69.7747 3.67899C70.652 4.13165 71.327 4.79272 71.7951 5.66218C72.2631 6.53165 72.4984 7.58935 72.4984 8.83753V8.85322C72.4984 10.1014 72.2655 11.1703 71.7998 12.0577C71.3341 12.9451 70.6591 13.6241 69.7794 14.0924C68.8974 14.5608 67.8366 14.7961 66.5948 14.7961H61.8672V3ZM66.2866 12.7524C67.0581 12.7524 67.7096 12.6045 68.2388 12.3109C68.768 12.0174 69.1702 11.5804 69.4454 11.0022C69.7206 10.4241 69.857 9.71148 69.857 8.85994V8.84426C69.857 8.02633 69.7159 7.33389 69.436 6.76695C69.1561 6.2 68.7492 5.77199 68.2177 5.47843C67.6861 5.18711 67.0416 5.04146 66.2866 5.04146H64.4591V12.7546H66.2866V12.7524Z"
      fill="#605E6B"
    />
    <path
      d="M74.2539 3H82.4578V5.03473H76.8458V7.86274H82.1403V9.80112H76.8458V12.7613H82.4578V14.7961H74.2539V3Z"
      fill="#605E6B"
    />
    <path
      d="M84.2445 3H89.3578C90.2845 3 91.0772 3.15014 91.7381 3.45266C92.399 3.75518 92.9047 4.18767 93.2575 4.7479C93.6103 5.31036 93.7844 5.98487 93.7844 6.77591V6.7916C93.7844 7.57143 93.5821 8.26162 93.1799 8.86442C92.7777 9.46723 92.232 9.89524 91.5452 10.1529L94.1536 14.7961H91.2112L88.911 10.5294H86.8341V14.7961H84.2422V3H84.2445ZM89.0756 8.6493C89.7154 8.6493 90.2187 8.48571 90.5856 8.15854C90.9525 7.83137 91.1336 7.37871 91.1336 6.80056V6.78487C91.1336 6.21793 90.9431 5.76751 90.5621 5.43585C90.1811 5.1042 89.673 4.93613 89.038 4.93613H86.8318V8.64706H89.0709L89.0756 8.6493Z"
      fill="#605E6B"
    />
    <path
      d="M95.9863 3H98.5783V7.79104H104.155V3H106.747V14.7983H104.155V9.83697H98.5783V14.7983H95.9863V3Z"
      fill="#605E6B"
    />
    <path
      d="M111.243 14.4577C110.465 14.0947 109.865 13.5838 109.442 12.9249C109.018 12.2661 108.807 11.4997 108.807 10.628V3H111.399V10.4062C111.399 10.9126 111.5 11.3541 111.704 11.7261C111.907 12.1003 112.203 12.3894 112.589 12.5978C112.974 12.8039 113.445 12.9092 114 12.9092C114.555 12.9092 115.025 12.8062 115.411 12.5978C115.797 12.3916 116.091 12.1003 116.291 11.7261C116.491 11.3518 116.592 10.9126 116.592 10.4062V3H119.184V10.628C119.184 11.4997 118.974 12.2639 118.553 12.9204C118.132 13.577 117.535 14.088 116.759 14.4532C115.983 14.8185 115.063 15 114 15C112.937 15 112.024 14.8185 111.246 14.4555L111.243 14.4577Z"
      fill="#605E6B"
    />
    <path
      d="M121.236 3H123.828V14.7983H121.236V3ZM122.532 12.9585H126.058C126.797 12.9585 127.363 12.8151 127.756 12.5283C128.151 12.2415 128.349 11.8269 128.349 11.2824V11.2667C128.349 10.9059 128.259 10.6034 128.078 10.3546C127.897 10.1059 127.629 9.91989 127.272 9.7944C126.914 9.66891 126.472 9.60616 125.945 9.60616H122.53V7.92997H125.637C126.368 7.92997 126.926 7.79104 127.307 7.51316C127.688 7.23529 127.878 6.8409 127.878 6.32773V6.31204C127.878 5.8437 127.709 5.48067 127.373 5.22521C127.034 4.96975 126.564 4.84202 125.957 4.84202H122.532V3H126.625C127.408 3 128.083 3.12101 128.65 3.36078C129.217 3.60056 129.654 3.94342 129.962 4.38711C130.27 4.83081 130.426 5.35518 130.426 5.96022V5.97591C130.426 6.41288 130.322 6.814 130.113 7.18151C129.904 7.54902 129.619 7.85378 129.259 8.09804C128.899 8.34005 128.49 8.49244 128.031 8.5507V8.6C128.61 8.63361 129.12 8.77479 129.567 9.03025C130.014 9.28347 130.365 9.61512 130.619 10.0274C130.873 10.4398 131 10.9036 131 11.4213V11.437C131 12.1227 130.826 12.7188 130.48 13.2185C130.134 13.7204 129.642 14.1081 129.005 14.3837C128.368 14.6594 127.606 14.7961 126.719 14.7961H122.532V12.9563V12.9585Z"
      fill="#605E6B"
    />
  </svg>
);
