import { GraphQLResolveInfo } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum Field {
  Building = 'Building',
  BuildingName = 'BuildingName',
  CoarseAggregate = 'CoarseAggregate',
  Facility = 'Facility',
  FloorName = 'FloorName',
  FloorNum = 'FloorNum',
  Floortype = 'Floortype',
  ItemName = 'ItemName',
  ItemStandard = 'ItemStandard',
  MaterialCategory = 'MaterialCategory',
  MaterialName = 'MaterialName',
  MaterialSubcategory = 'MaterialSubcategory',
  Member = 'Member',
  MemberName = 'MemberName',
  Result = 'Result',
  Slump = 'Slump',
  Strength = 'Strength',
  Zone = 'Zone',
  DbId = 'dbId',
  Id = 'id'
}

export type Mutation = {
  __typename?: 'Mutation';
  setCurrentProperty?: Maybe<Array<Maybe<Node>>>;
};


export type MutationSetCurrentPropertyArgs = {
  data?: InputMaybe<Array<InputMaybe<NodeInput>>>;
};

export type Node = {
  __typename?: 'Node';
  Building: Scalars['String'];
  BuildingName: Scalars['String'];
  CoarseAggregate: Scalars['String'];
  Facility: Scalars['String'];
  FloorName: Scalars['String'];
  FloorNum: Scalars['String'];
  Floortype: Scalars['String'];
  ItemName: Scalars['String'];
  ItemStandard: Scalars['String'];
  MaterialCategory: Scalars['String'];
  MaterialName: Scalars['String'];
  MaterialSubcategory: Scalars['String'];
  Member: Scalars['String'];
  MemberName: Scalars['String'];
  Result: Scalars['String'];
  Slump: Scalars['String'];
  Strength: Scalars['String'];
  Zone: Scalars['String'];
  dbId?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
};

export type NodeInput = {
  Building: Scalars['String'];
  BuildingName: Scalars['String'];
  CoarseAggregate: Scalars['String'];
  Facility: Scalars['String'];
  FloorName: Scalars['String'];
  FloorNum: Scalars['String'];
  Floortype: Scalars['String'];
  ItemName: Scalars['String'];
  ItemStandard: Scalars['String'];
  MaterialCategory: Scalars['String'];
  MaterialName: Scalars['String'];
  MaterialSubcategory: Scalars['String'];
  Member: Scalars['String'];
  MemberName: Scalars['String'];
  Result: Scalars['String'];
  Slump: Scalars['String'];
  Strength: Scalars['String'];
  Zone: Scalars['String'];
  dbId?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  allNode?: Maybe<Array<Maybe<Node>>>;
  getNodesByField?: Maybe<Array<Maybe<Node>>>;
  getNodesById?: Maybe<Array<Maybe<Node>>>;
};


export type QueryGetNodesByFieldArgs = {
  field: Field;
  value: Scalars['String'];
};


export type QueryGetNodesByIdArgs = {
  id: Scalars['String'];
};

export type GetAllNodeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllNodeQuery = { __typename?: 'Query', allNode?: Array<{ __typename?: 'Node', id: string, dbId?: number | null, BuildingName: string, MaterialCategory: string, MaterialSubcategory: string, Facility: string, Building: string, Floortype: string, FloorNum: string, FloorName: string, Zone: string, Member: string, MemberName: string, MaterialName: string, CoarseAggregate: string, Strength: string, Slump: string, ItemName: string, ItemStandard: string, Result: string } | null> | null };

export type GetNodesByFieldQueryVariables = Exact<{
  field: Field;
  value: Scalars['String'];
}>;


export type GetNodesByFieldQuery = { __typename?: 'Query', getNodesByField?: Array<{ __typename?: 'Node', id: string, dbId?: number | null, BuildingName: string, MaterialCategory: string, MaterialSubcategory: string, Facility: string, Building: string, Floortype: string, FloorNum: string, FloorName: string, Zone: string, Member: string, MemberName: string, MaterialName: string, CoarseAggregate: string, Strength: string, Slump: string, ItemName: string, ItemStandard: string, Result: string } | null> | null };

export type GetNodesByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetNodesByIdQuery = { __typename?: 'Query', getNodesById?: Array<{ __typename?: 'Node', id: string, dbId?: number | null, BuildingName: string, MaterialCategory: string, MaterialSubcategory: string, Facility: string, Building: string, Floortype: string, FloorNum: string, FloorName: string, Zone: string, Member: string, MemberName: string, MaterialName: string, CoarseAggregate: string, Strength: string, Slump: string, ItemName: string, ItemStandard: string, Result: string } | null> | null };


export const GetAllNodeDocument = gql`
    query getAllNode {
  allNode {
    id @client
    dbId @client
    BuildingName @client
    MaterialCategory @client
    MaterialSubcategory @client
    Facility @client
    Building @client
    Floortype @client
    FloorNum @client
    FloorName @client
    Zone @client
    Member @client
    MemberName @client
    MaterialName @client
    CoarseAggregate @client
    Strength @client
    Slump @client
    ItemName @client
    ItemStandard @client
    Result @client
  }
}
    `;

/**
 * __useGetAllNodeQuery__
 *
 * To run a query within a React component, call `useGetAllNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNodeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllNodeQuery(baseOptions?: Apollo.QueryHookOptions<GetAllNodeQuery, GetAllNodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllNodeQuery, GetAllNodeQueryVariables>(GetAllNodeDocument, options);
      }
export function useGetAllNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllNodeQuery, GetAllNodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllNodeQuery, GetAllNodeQueryVariables>(GetAllNodeDocument, options);
        }
export type GetAllNodeQueryHookResult = ReturnType<typeof useGetAllNodeQuery>;
export type GetAllNodeLazyQueryHookResult = ReturnType<typeof useGetAllNodeLazyQuery>;
export type GetAllNodeQueryResult = Apollo.QueryResult<GetAllNodeQuery, GetAllNodeQueryVariables>;
export const GetNodesByFieldDocument = gql`
    query getNodesByField($field: Field!, $value: String!) {
  getNodesByField(field: $field, value: $value) {
    id @client
    dbId @client
    BuildingName @client
    MaterialCategory @client
    MaterialSubcategory @client
    Facility @client
    Building @client
    Floortype @client
    FloorNum @client
    FloorName @client
    Zone @client
    Member @client
    MemberName @client
    MaterialName @client
    CoarseAggregate @client
    Strength @client
    Slump @client
    ItemName @client
    ItemStandard @client
    Result @client
  }
}
    `;

/**
 * __useGetNodesByFieldQuery__
 *
 * To run a query within a React component, call `useGetNodesByFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodesByFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodesByFieldQuery({
 *   variables: {
 *      field: // value for 'field'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useGetNodesByFieldQuery(baseOptions: Apollo.QueryHookOptions<GetNodesByFieldQuery, GetNodesByFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNodesByFieldQuery, GetNodesByFieldQueryVariables>(GetNodesByFieldDocument, options);
      }
export function useGetNodesByFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNodesByFieldQuery, GetNodesByFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNodesByFieldQuery, GetNodesByFieldQueryVariables>(GetNodesByFieldDocument, options);
        }
export type GetNodesByFieldQueryHookResult = ReturnType<typeof useGetNodesByFieldQuery>;
export type GetNodesByFieldLazyQueryHookResult = ReturnType<typeof useGetNodesByFieldLazyQuery>;
export type GetNodesByFieldQueryResult = Apollo.QueryResult<GetNodesByFieldQuery, GetNodesByFieldQueryVariables>;
export const GetNodesByIdDocument = gql`
    query getNodesById($id: String!) {
  getNodesById(id: $id) {
    id @client
    dbId @client
    BuildingName @client
    MaterialCategory @client
    MaterialSubcategory @client
    Facility @client
    Building @client
    Floortype @client
    FloorNum @client
    FloorName @client
    Zone @client
    Member @client
    MemberName @client
    MaterialName @client
    CoarseAggregate @client
    Strength @client
    Slump @client
    ItemName @client
    ItemStandard @client
    Result @client
  }
}
    `;

/**
 * __useGetNodesByIdQuery__
 *
 * To run a query within a React component, call `useGetNodesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNodesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNodesByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNodesByIdQuery(baseOptions: Apollo.QueryHookOptions<GetNodesByIdQuery, GetNodesByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNodesByIdQuery, GetNodesByIdQueryVariables>(GetNodesByIdDocument, options);
      }
export function useGetNodesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNodesByIdQuery, GetNodesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNodesByIdQuery, GetNodesByIdQueryVariables>(GetNodesByIdDocument, options);
        }
export type GetNodesByIdQueryHookResult = ReturnType<typeof useGetNodesByIdQuery>;
export type GetNodesByIdLazyQueryHookResult = ReturnType<typeof useGetNodesByIdLazyQuery>;
export type GetNodesByIdQueryResult = Apollo.QueryResult<GetNodesByIdQuery, GetNodesByIdQueryVariables>;


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Field: Field;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Mutation: ResolverTypeWrapper<{}>;
  Node: ResolverTypeWrapper<Node>;
  NodeInput: NodeInput;
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Boolean: Scalars['Boolean'];
  Int: Scalars['Int'];
  Mutation: {};
  Node: Node;
  NodeInput: NodeInput;
  Query: {};
  String: Scalars['String'];
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  setCurrentProperty?: Resolver<Maybe<Array<Maybe<ResolversTypes['Node']>>>, ParentType, ContextType, Partial<MutationSetCurrentPropertyArgs>>;
};

export type NodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Node'] = ResolversParentTypes['Node']> = {
  Building?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  BuildingName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  CoarseAggregate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Facility?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FloorName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  FloorNum?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Floortype?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ItemName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ItemStandard?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  MaterialCategory?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  MaterialName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  MaterialSubcategory?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Member?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  MemberName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Result?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Slump?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Strength?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  Zone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dbId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  allNode?: Resolver<Maybe<Array<Maybe<ResolversTypes['Node']>>>, ParentType, ContextType>;
  getNodesByField?: Resolver<Maybe<Array<Maybe<ResolversTypes['Node']>>>, ParentType, ContextType, RequireFields<QueryGetNodesByFieldArgs, 'field' | 'value'>>;
  getNodesById?: Resolver<Maybe<Array<Maybe<ResolversTypes['Node']>>>, ParentType, ContextType, RequireFields<QueryGetNodesByIdArgs, 'id'>>;
};

export type Resolvers<ContextType = any> = {
  Mutation?: MutationResolvers<ContextType>;
  Node?: NodeResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
};

