import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { forgeViewerInitializeThunk } from "./thunks";

export * from "./thunks";

interface State {
  loading: boolean;
  isModelLoaded: boolean;
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  error: boolean;
  message: string;
  doc: Autodesk.Viewing.Document | null;
  viewer: Autodesk.Viewing.GuiViewer3D | null;
  model: Autodesk.Viewing.Model | null;
  urn: string | null;
  gltf: string | null;
}

const initialState: State = {
  loading: false,
  isModelLoaded: false,
  accessToken: "",
  tokenType: "",
  expiresIn: 0,
  error: false,
  message: "",
  doc: null,
  viewer: null,
  model: null,
  urn: null,
  gltf: null,
};

export const forgeSlice = createSlice({
  name: "autodeskForge",
  initialState,
  reducers: {
    setUrn3D(state, action: PayloadAction<string>) {
      state.urn = action.payload;
    },
    setGlTF(state, action: PayloadAction<string>) {
      state.gltf = action.payload;
    },
    setModel(state, action: PayloadAction<Autodesk.Viewing.Model>) {
      state.model = action.payload;
    },
    setIsModelLoaded(state, action: PayloadAction<boolean>) {
      state.isModelLoaded = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(forgeViewerInitializeThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgeViewerInitializeThunk.fulfilled, (state, action) => {
        const {
          tokenData: { access_token, token_type, expires_in },
          doc,
          viewer,
        } = action.payload;
        state.loading = false;
        state.accessToken = access_token;
        state.tokenType = token_type;
        state.expiresIn = expires_in;
        state.doc = doc;
        // @ts-ignore
        state.viewer = viewer;
      })
      .addCase(forgeViewerInitializeThunk.rejected, (state, response) => {
        state.loading = false;
        state.error = true;
        state.message = response.error.message || "";
      });
    // .addCase(fetchCsvThunk.pending, (state) => {
    //   state.loading = true;
    // });
  },
});

export const { setUrn3D, setModel, setIsModelLoaded } = forgeSlice.actions;
