import { createTheme, ThemeOptions } from "@mui/material/styles";
import { colors } from "./colors";
export * from "./colors";

const customPalette = {
  primary: {
    main: colors.primary.default,
    light: colors.primary.light,
    dark: colors.primary.darkmode,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.secondary.default,
    light: colors.secondary.light,
    dark: colors.secondary.darkmode,
    contrastText: colors.white,
  },
  success: {
    main: colors.success.default,
    light: colors.success.light,
    dark: colors.success.darkmode,
    contrastText: colors.white,
  },
  warning: {
    main: colors.warning.default,
    light: colors.warning.light,
    dark: colors.warning.darkmode,
    contrastText: colors.white,
  },
  error: {
    main: colors.error.default,
    light: colors.error.light,
    dark: colors.error.darkmode,
    contrastText: colors.white,
  },
};
const fontFamily = [
  "Spoqa Han Sans Neo",
  "Pretendard",
  "Poppins",
  "-apple-system",
  "sans-serif",
].join(", ");
const customTypography = {
  fontFamily,
};
const customBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

const options: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // "@global": {
        html: {
          WebkitFontSmoothing: "auto",
          scrollbarWidth: "none",
        },
        "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active":
          {
            WebkitBoxShadow: "0 0 0 500px white inset !important",
          },
        "::-webkit-scrollbar": {
          width: 2,
          height: 0,
        },
        "::-webkit-scrollbar-button": {
          background: "#ccc",
        },
        "::-webkit-scrollbar-track-piece": {
          background: "#888",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#eee",
        },
        // },
      },
    },
  },
  palette: {
    ...customPalette,
  },
  typography: { ...customTypography },
  breakpoints: {
    ...customBreakpoints,
  },
};

export const customTheme = createTheme(options);
