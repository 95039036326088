import * as React from "react";
import { Box, Button, ButtonGroup, Grid } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
  setUrn3D,
  fetchCsvThunk,
  parseCsv,
  getNodesByField,
} from "store";
import {
  // useGetNodesByFieldLazyQuery,
  Field,
} from "generated";
import { compact } from "lodash";
import { ActionAreaCard, ActionAreaCardProp } from "./ActionCard";
import img0 from "./img0.jpg";
import img1 from "./img1.jpg";
import img2 from "./img2.jpg";
import img3 from "./img3.jpg";
import texture1 from "./texture1.jpg";
import texture2 from "./texture2.png";
import imagePatch from "./image-patch.png";
import { setMaterial } from "./setMaterial";
import { PropertyTable } from "./PropertyTable";

const materials: Omit<ActionAreaCardProp, "handleClick">[] = [
  { title: "title1", content: "content1", image: img0 },
  { title: "title2", content: "content2", image: img1 },
  { title: "title3", content: "content3", image: img2 },
  { title: "title4", content: "content4", image: img3 },
  { title: "title5", content: "content5", image: texture1 },
  { title: "title6", content: "content6", image: texture2 },
  { title: "title7", content: "content7", image: imagePatch },
];

// const materials: Omit<ActionAreaCardProp, "handleClick">[] = [
//   { title: "title1", content: "content1", image: "img1.png" },
//   { title: "title2", content: "content2", image: "img2.jpg" },
//   { title: "title3", content: "content3", image: "img3.jpg" },
//   { title: "title4", content: "content4", image: "img4.jpg" },
// ];

const data = [
  {
    id: 0,
    name: "Test",
    fileType: "gltf",
    // urn: "models/1/minibuilding.gltf",
    urn: "models/6/model6.gltf",
    // urn: "models/6/model6.gltf",
    renderer: "forge",
  },
];

export function Content() {
  const dispatch = useAppDispatch();
  // const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedFloor, setSelectedFloor] = React.useState("");
  React.useEffect(() => {
    dispatch(setUrn3D(data[0].urn));
    dispatch(fetchCsvThunk("models/1/conc.csv")).then(() => {
      dispatch(parseCsv());
    });
  }, [dispatch]);
  const { viewer, model, loading, isModelLoaded } = useAppSelector(
    (state) => state.forge,
  );
  const { floorName, aggregateSelectedProperties, tableSelectedProperties } =
    useAppSelector((state) => state.model);
  React.useEffect(() => {
    if (isModelLoaded && viewer) {
      console.log(viewer);
    }
  }, [isModelLoaded, viewer]);
  React.useEffect(() => {
    if (aggregateSelectedProperties.length !== 0) {
      const ids = compact(
        aggregateSelectedProperties.map((node) => {
          if (!node) return null;
          const { dbId } = node;
          return dbId;
        }),
      );
      if (viewer && ids.length !== 0) {
        viewer.hideAll();
        viewer.show(ids);
        viewer.fitToView();
        // viewer.select(ids);
      }
    }
  }, [dispatch, model, aggregateSelectedProperties, viewer]);
  // React.useEffect(() => {
  //   return () => {
  //     if (viewer) {
  //       viewer.tearDown();
  //     }
  //   };
  // }, [viewer]);
  // const [getData] = useGetNodesByFieldLazyQuery({
  //   onCompleted(data) {
  //     const { getNodesByField } = data;
  //     if (!getNodesByField) return;
  //     const ids = compact(
  //       getNodesByField.map((node) => {
  //         if (!node) return null;
  //         const { dbId } = node;
  //         return dbId;
  //       }),
  //     );
  //     if (viewer) {
  //       viewer.hideAll();
  //       viewer.show(ids);
  //       viewer.select(ids);
  //     }
  //   },
  // });
  const fetchData = React.useCallback(
    (floorName: string) => {
      // getData({ variables: { field: Field.FloorName, value: floorName } });
      dispatch(getNodesByField({ field: Field.FloorName, value: floorName }));
    },
    [dispatch],
  );
  React.useEffect(() => {
    if (selectedFloor) {
      // getData({ variables: { field: Field.FloorName, value: selectedFloor } });
      fetchData(selectedFloor);
    }
  }, [fetchData, selectedFloor]);
  const handleSelect = (floor: string) => {
    setSelectedFloor(floor);
  };
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        "& > *": {
          m: 1,
          p: 1,
        },
      }}
    >
      {!loading ? (
        <>
          <Button
            onClick={() => {
              if (viewer) {
                viewer.showAll();
                viewer.fitToView();
              }
            }}
          >
            Reset
          </Button>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {floorName.map((floor) => (
              <Button
                key={floor}
                onClick={() => {
                  handleSelect(floor);
                }}
              >
                {floor}
              </Button>
            ))}
          </ButtonGroup>
          <Grid container sx={{ width: "100%" }}>
            {materials.map((item) => {
              const dbId = compact(
                tableSelectedProperties.map((item) => item.dbId),
              );
              const handleClick = async () => {
                if (viewer && model) {
                  await setMaterial(viewer, model, dbId, item.image);
                }
              };
              return (
                <Grid lg={3} item key={item.image}>
                  <ActionAreaCard
                    title={item.title}
                    content={item.content}
                    image={item.image}
                    handleClick={handleClick}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      ) : null}
      <PropertyTable data={aggregateSelectedProperties} />
    </Box>
  );
}
