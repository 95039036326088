import * as React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from "@mui/material";

export interface ActionAreaCardProp {
  image: string;
  handleClick(): void;
  title?: string;
  content?: string;
}

export function ActionAreaCard(props: ActionAreaCardProp) {
  const { image, title, content, handleClick } = props;
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea onClick={handleClick}>
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt="green iguana"
        />
        <CardContent>
          {title ? (
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
          ) : null}
          {content ? (
            <Typography variant="body2" color="text.secondary">
              {content}
            </Typography>
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
