import { styled, Box, AppBar, Toolbar, Button } from "@mui/material";
import { colors } from "contexts";

export const Wrap = styled(Box)(() => ({
  display: "flex",
}));

export const BuilderhubAppBar = styled(AppBar)(() => ({
  background: colors.white,
  color: colors.black,
  borderBottom: `1px solid ${colors.grey._10}`,
  boxShadow: "none",
}));

export const BuilderhubToolbar = styled(Toolbar)(() => ({
  "@media (min-width: 600px)": {
    padding: "0 10%",
  },
}));

export const ButtonDiv = styled(`div`)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  padding: `0 0 0 5%`,
}));

export const ServiceBtn = styled(Button)(() => ({
  fontSize: 16,
  fontWeight: 500,
  color: colors.grey._40,
  "@media (max-width: 1090px)": {
    display: "none",
  },
  padding: `6px 12px`,
}));

export const UserBtn = styled(Button)(() => ({
  fontSize: 16,
  fontWeight: 500,
  color: colors.grey._60,
  padding: `6px 12px`,
}));

export const ProjectBtn = styled(Button)(() => ({
  fontSize: 16,
  fontWeight: 500,
  color: colors.white,
  borderRadius: 9999,
  marginLeft: 8,
  backgroundColor: colors.purple._60,
}));
