import * as React from "react";
import { SVGProps } from "react";

export const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2325 0H2.7675C1.23905 0 0 1.23905 0 2.7675V15.2325C0 16.7609 1.23905 18 2.7675 18H15.2325C16.7609 18 18 16.7609 18 15.2325V2.7675C18 1.23905 16.7609 0 15.2325 0Z"
      fill="#6649E2"
    />
    <path
      d="M5.90914 4.38086H4.05414C3.91745 4.38086 3.80664 4.49167 3.80664 4.62836V13.3759C3.80664 13.5125 3.91745 13.6234 4.05414 13.6234H5.90914C6.04583 13.6234 6.15664 13.5125 6.15664 13.3759V4.62836C6.15664 4.49167 6.04583 4.38086 5.90914 4.38086Z"
      fill="white"
    />
    <path
      d="M14.8127 11.9689H13.1477C13.1902 11.9689 13.2977 11.8164 13.3277 11.7839C13.3802 11.7264 13.4252 11.6639 13.4677 11.5964C13.8677 10.9739 13.8952 10.1689 13.6502 9.48641C13.3802 8.73391 12.7802 8.25891 12.0177 8.05141C12.0627 8.06391 12.1727 7.99891 12.2127 7.97891C12.8477 7.65141 12.9327 6.75641 12.7852 6.13891C12.7277 5.89891 12.6302 5.66641 12.4927 5.46141C12.2577 5.11641 11.9227 4.84891 11.4852 4.66141C11.0477 4.47391 10.5227 4.37891 9.91266 4.37891H7.03266C6.89766 4.37891 6.78516 4.48891 6.78516 4.62641V5.79141C6.78516 5.92641 6.89516 6.03891 7.03266 6.03891H9.28516C9.67766 6.03891 9.98766 6.12891 10.2127 6.31141C10.4377 6.49391 10.5527 6.74391 10.5527 7.06391C10.5527 7.06391 10.5752 7.43891 10.2302 7.71891C9.99266 7.91141 9.58266 7.91141 9.11266 7.91141H7.03516C6.90016 7.91141 6.78766 8.02141 6.78766 8.15891V9.15141C6.78766 9.28641 6.89766 9.39891 7.03516 9.39891H9.73016C10.0052 9.39891 10.2902 9.42391 10.5527 9.52391C10.8427 9.63641 11.1577 9.90141 11.2477 10.2089C11.3902 10.6914 11.3402 11.3364 10.9127 11.6639C10.6552 11.8614 10.2852 11.9614 9.80266 11.9614H7.03766C6.90266 11.9614 6.79016 12.0714 6.79016 12.2089V13.3739C6.79016 13.5089 6.90016 13.6214 7.03766 13.6214H14.8227C14.9577 13.6214 15.0702 13.5114 15.0702 13.3739V12.2139C15.0702 12.0789 14.9602 11.9664 14.8227 11.9664L14.8127 11.9689Z"
      fill="white"
    />
  </svg>
);
