import React from "react";
import { BrowserRouter } from "react-router-dom";
import { BuilderhubThemeProvider } from "../ThemeProvider";
import { StoreProvider } from "../StoreProvider";
import { ApolloClientProvider } from "../ApolloProvider";

export function RootProvider({ children }: { children: React.ReactNode }) {
  return (
    <StoreProvider>
      <ApolloClientProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <BuilderhubThemeProvider>{children}</BuilderhubThemeProvider>
        </BrowserRouter>
      </ApolloClientProvider>
    </StoreProvider>
  );
}
