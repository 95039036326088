import * as React from "react";
import {
  Box,
  Divider,
  Drawer,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Builderhub } from "components";
import {
  Wrap,
  BuilderhubAppBar,
  BuilderhubToolbar,
  ButtonDiv,
  ServiceBtn,
} from "./Styles";
import { useNavigate, useLocation } from "react-router-dom";
import { colors } from "contexts";

interface Props {
  window?: () => Window;
  children: React.ReactNode;
}

const navItems = [
  { name: "Autodesk Forge", path: "" },
  { name: "Three.js", path: "three" },
];

const drawerWidth = 240;

export function Layout(props: Props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigation = useNavigate();
  const location = useLocation();
  const drawer = (
    <Box
      component="div"
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center" }}
    >
      <Button
        sx={{ my: 2, mt: 3, cursor: "pointer" }}
        onClick={() => navigation("/")}
      >
        <Builderhub />
      </Button>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: "left" }}>
              <ListItemText
                sx={{
                  color:
                    location.pathname === item.path
                      ? colors.purple._60
                      : colors.grey._40,
                }}
                primary={item.name}
                onClick={() => {
                  // @ts-ignore
                  window.location.replace(item.path);
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Wrap>
      <BuilderhubAppBar>
        <BuilderhubToolbar>
          <div style={{ width: 145 }}></div>
          <ButtonDiv>
            <Box component="div" sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) => (
                <ServiceBtn
                  key={item.name}
                  sx={{
                    color:
                      location.pathname === item.path
                        ? colors.purple._60
                        : colors.grey._40,
                  }}
                  onClick={() => navigation(item.path)}
                >
                  {item.name}
                </ServiceBtn>
              ))}
            </Box>
          </ButtonDiv>
        </BuilderhubToolbar>
      </BuilderhubAppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ width: "100%" }}>
        <BuilderhubToolbar />
        {children}
      </Box>
    </Wrap>
  );
}
