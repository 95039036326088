import { createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../..";
import { setModel, setIsModelLoaded } from ".";
import { isEmpty, isEqual } from "lodash";
import {
  setCurrentSelectedByDbId,
  // setCurrentSelectedById,
  // SelectedPropertyType,
} from "../model";

// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
// import * as THREE from "three";

const uri = "https://api.demo.platform.builderhub.io/forge_token";

async function api<T>(url: string): Promise<T> {
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
}
export const forgeViewerInitializeThunk = createAsyncThunk(
  "forge/viewerInit",
  forgeInitialize,
);

// export const fetchCsvThunk = createAsyncThunk("csv/fetch", fetchCsv);

type ForgeTokenPayload = {
  access_token: string;
  token_type: "Bearer";
  expires_in: number;
  expires_at: number;
};

/**
 * It gets the current user's session, gets the JWT token from the session, creates a GraphQL client
 * with the JWT token as the authorization header, gets the Forge SDK from the client, and then gets
 * the Forge token from the SDK
 * @returns The token is being returned.
 */
async function getForgeTokenData() {
  const token = await api<ForgeTokenPayload>(uri);
  return token;
}

interface ForgeInitializerParams {
  el: HTMLDivElement;
  urn: string;
  glTF?: string;
}

interface ForgeIntializerResolvedValue {
  /**
   * The Autodesk.Viewing.Document loaded document
   */
  doc: Autodesk.Viewing.Document | null;
  /**
   * The Forge token data
   */
  tokenData: {
    __typename?: "ForgeToken" | undefined;
    access_token: string;
    token_type: string;
    expires_in: number;
  };
  /**
   * Forge Viewer
   */
  viewer: Autodesk.Viewing.Viewer3D;
}

/**
 * It initializes the Forge viewer, loads the model, and returns the viewer, the model, and the Forge
 * token data
 * @param {ForgeInitializerParams}  - `el` - the DOM element where the viewer will be rendered
 * @returns An object with the following properties:
 * - doc: The loaded document
 * - tokenData: The token data
 * - viewer: The viewer
 */
async function forgeInitialize({
  el,
  urn,
  glTF,
}: ForgeInitializerParams): Promise<ForgeIntializerResolvedValue> {
  const tokenData = await getForgeTokenData();
  const options: Autodesk.Viewing.InitializerOptions = {
    env: "AutodeskProduction",
    api: "derivativeV2",
    getAccessToken(callback?) {
      getForgeTokenData().then((token) => {
        const { access_token, expires_in } = token;
        if (callback) {
          callback(access_token, expires_in);
        }
      });
    },
  };
  const viewer = await forgeViewerInit(options, el);
  // // @ts-ignore
  // await viewer.start(undefined, undefined, undefined, undefined, {
  //   webglInitParams: { useWebGL2: false },
  // });
  await viewer.start();
  viewer.setGhosting(false);
  viewer.setLightPreset(4);

  // @ts-ignore
  viewer.impl.toggleShadows(true);
  // @ts-ignore
  viewer.impl.setShadowLightDirection(new THREE.Vector3(-1, 2, 1));
  const doc = glTF
    ? await forgeViewerGlTFLoad(viewer, glTF)
    : await forgeViewerLoad(urn);
  viewer.addEventListener(Autodesk.Viewing.TEXTURES_LOADED_EVENT, (ev) => {
    if (ev.model._isSwatch) {
      console.log(ev.model);
    }
  });
  if (doc) {
    const defaultModel = doc.getRoot().getDefaultGeometry();
    viewer.loadDocumentNode(doc, defaultModel, {
      preserveView: true,
      keepCurrentModels: true,
      loadAsHidden: true,
    });
  }
  viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
    store.dispatch(setModel(viewer.model));
    store.dispatch(setIsModelLoaded(true));
    // viewer.getObjectTree(console.log, console.log);
    // store.dispatch(setDbId(viewer.model));
  });
  const handleSelect = () => {
    const selection = viewer.getSelection();
    const {
      model: { currentSelectedId, dbProperties },
    } = store.getState();
    if (!isEmpty(selection)) {
      if (isEmpty(currentSelectedId)) {
        store.dispatch(setCurrentSelectedByDbId(selection));
      } else {
        const dbIds = isEmpty(currentSelectedId)
          ? []
          : currentSelectedId.reduce((pre, cur) => {
              return [
                ...pre,
                ...dbProperties
                  .filter((item) => item.id === cur)
                  .map((item) => item.dbId),
              ];
            }, [] as number[]);
        if (!isEqual(selection, dbIds) || selection.length !== dbIds.length) {
          store.dispatch(setCurrentSelectedByDbId(selection));
        }
      }
    }
  };
  viewer.addEventListener(
    Autodesk.Viewing.SELECTION_CHANGED_EVENT,
    handleSelect,
  );
  // // @ts-ignore
  // viewer.modelstructure.onViewerSelect = handleSelect;
  // await new Promise((resolve) => {
  //   viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
  //     store.dispatch(setModel(viewer.model));
  //     resolve(true);
  //     // store.dispatch(setDbId(viewer.model));
  //   });
  // });
  return { doc, tokenData, viewer };
}

/**
 * It returns a promise that resolves to a viewer object
 * @param options - Autodesk.Viewing.InitializerOptions
 * @param {HTMLDivElement} el - HTMLDivElement - the div element that will contain the viewer
 * @param {string} urn - The URN of the model you want to load.
 * @returns A promise that resolves to a viewer object.
 */
async function forgeViewerInit(
  options: Autodesk.Viewing.InitializerOptions,
  el: HTMLDivElement,
): Promise<Autodesk.Viewing.GuiViewer3D> {
  return new Promise((resolve, reject) => {
    Autodesk.Viewing.Initializer(options, async function onInitialized() {
      const viewer = new Autodesk.Viewing.GuiViewer3D(el);
      await viewer.loadExtension("Autodesk.Viewing.Popout");
      await viewer.loadExtension("Autodesk.ModelStructure");
      // await viewer.loadExtension("Autodesk.AEC.LevelsExtension");
      // const extensions =
      //   Autodesk.Viewing.theExtensionManager.getRegisteredExtensions();
      resolve(viewer);
    });
  });
}

/**
 * It loads a Forge model from a URN and returns a promise that resolves to a Forge document
 * @param {string} urn - The URN of the model you want to load.
 * @returns A promise that resolves to a document object.
 */
async function forgeViewerLoad(
  urn: string,
): Promise<Autodesk.Viewing.Document> {
  return new Promise((resolve, reject) => {
    Autodesk.Viewing.Document.load(
      urn,
      function onDocumentLoadSuccess(doc) {
        resolve(doc);
      },
      function onDocumentLoadFailure(error) {
        // console.log({ error });
        reject(error);
      },
    );
  });
}

function forgeViewerGlTFLoad(
  viewer: Autodesk.Viewing.GuiViewer3D,
  glTF: string,
): Promise<Autodesk.Viewing.Document | null> {
  return new Promise((resolve, reject) => {
    viewer
      .loadExtension("Autodesk.glTF")
      .then(() => {
        viewer.loadModel(glTF, { glTF });
        resolve(null);
      })
      .catch(reject);
  });
}

// async function fetchCsv(path: string) {
//   const response = await fetch(path);
//   const reader = response.body?.getReader();
//   const decoder = new TextDecoder("utf-8");
//   if (!reader) throw new Error("No reader found");
//   const result = await reader.read();
//   return decoder.decode(result.value);
// }

// async function threeGlTFLoad(glTF: string) {
//   return new Promise((resolve, reject) => {
//     const scene = new THREE.Scene();
//     const renderer = new THREE.WebGLRenderer({ antialias: true });
//     const loader = new GLTFLoader();
//     const render = (scene: THREE.Scene, camera: )
//     loader.load(glTF, (gltf) => {
//       const model = gltf.scene.children[0];
//       model.scale.set(0.5, 0.5, 0.5);
//       scene.add(gltf.scene as unknown as THREE.Object3D);
//       resolve({scene, renderer})
//     });
//   });
// }
