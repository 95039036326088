import React from "react";
import { Grid } from "@mui/material";
import { Forge } from "./ForgeViewer";
import { Content } from "./Content";
import { useAppDispatch, useAppSelector, setDbIdThunk } from "store";

export function ForgeViewer() {
  const dispatch = useAppDispatch();
  const {
    forge: { viewer, model, isModelLoaded },
    model: { properties },
  } = useAppSelector((state) => state);
  React.useEffect(() => {
    if (isModelLoaded && model) {
      dispatch(setDbIdThunk({ properties, model }));
    }
  }, [dispatch, isModelLoaded, model, properties]);
  React.useEffect(() => {
    return () => {
      if (viewer) {
        viewer.tearDown();
      }
    };
  }, [viewer]);
  return (
    <Grid
      sx={{
        width: "100vw",
        height: { lg: "100vh", xs: "auto" },
        p: { lg: "0 0", xs: "0 1rem" },
        position: "relative",
      }}
      container
    >
      <Grid
        item
        sx={{
          width: { lg: "58.33%", xs: "100%" },
          // width: { lg: "80%", xs: "100%" },
          height: { lg: "100%", xs: "40vh" },
          pt: "64px",
          position: { lg: "fixed", xs: "unset" },
          top: 0,
        }}
        // lg={10}
      >
        <Forge />
      </Grid>
      <Grid
        item
        sx={{
          marginLeft: { lg: "58.33%", xs: 0 },
          // marginLeft: { lg: "80%", xs: 0 },
          width: "100%",
        }}
        // lg={2}
      >
        {/* <div> */}
        <Content />
        {/* </div> */}
      </Grid>
    </Grid>
  );
}
