import React from "react";

// 3D urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi8lRTElODQlOEIlRTElODUlQTclRTElODYlQTglRTElODQlODklRTElODUlQTElRTElODYlQjclRTElODQlODMlRTElODUlQTklRTElODYlQkMlMjA3MjEtMzAlMjAlRTElODQlODAlRTElODUlQjMlRTElODYlQUIlRTElODQlODUlRTElODUlQjUlRTElODYlQUIlRTElODQlODklRTElODUlQTIlRTElODYlQkMlRTElODQlOTIlRTElODUlQUElRTElODYlQUYlRTElODQlODklRTElODUlQjUlRTElODQlODklRTElODUlQTUlRTElODYlQUYtVGQ5ak02QU56Lm53Yw
// 2D urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YmhwbGYtYnVja2V0LWRldi9BLTIwMV8yMTUlMjAlRTElODQlOTElRTElODUlQTclRTElODYlQkMlRTElODQlODYlRTElODUlQTclRTElODYlQUIlRTElODQlODMlRTElODUlQTktU2tKTWZseTZnLmR3Zw
interface ForgeViewerPropType extends React.HTMLProps<HTMLDivElement> {
  urn: string;
  viewer: Autodesk.Viewing.GuiViewer3D | null;
  viewerRenderer(ref: React.RefObject<HTMLDivElement>, urn: string): void;
}

export function ForgeViewer(props: ForgeViewerPropType) {
  const { urn, viewer, viewerRenderer, ...rest } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (viewer) {
      viewer.tearDown();
    }
    viewerRenderer(ref, urn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, viewerRenderer, urn]);
  return <div ref={ref} id="forgeViewer" {...rest}></div>;
}
