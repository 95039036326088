import React from "react";
import {
  Canvas,
  useLoader,
  ObjectMap,
  useThree,
  useFrame,
  extend,
} from "@react-three/fiber";
import {
  Environment,
  useGLTF,
  OrbitControls,
  FlyControls,
  FirstPersonControls,
  PerspectiveCamera,
} from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useAppSelector } from "store";
import * as THREE from "three";

const mapboxgl = window.mapboxgl;
mapboxgl.accessToken =
  "pk.eyJ1IjoiZXVuY2h1cm4iLCJhIjoiY2p3cG52cDUyMDhyMTN6cWlybHRhaDBxayJ9.ebHSzW6LO2mU8gKupfSj5w";

interface ThreeViewerProps {
  gltfPath: string;
}

export function ThreeViewer(props: ThreeViewerProps) {
  // const gltf = useLoader(GLTFLoader, props.gltfPath);
  const ref = React.useRef<HTMLElement>();
  const gltf = useGLTF(props.gltfPath);
  console.log({ gltf });
  const box = new THREE.Box3().setFromObject(
    gltf.scene as unknown as THREE.Object3D,
  );
  const size = box.getSize(new THREE.Vector3());
  const center = box.getCenter(new THREE.Vector3());
  // gltf.scene.position.x += gltf.scene.position.x - center.x;
  // gltf.scene.position.y += gltf.scene.position.y - center.y;
  // gltf.scene.position.z += gltf.scene.position.z - center.z;
  console.log({ size, center });
  React.useEffect(() => {
    // const map = new mapboxgl.Map({
    //   container: "map",
    //   // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
    //   style: "mapbox://styles/mapbox/light-v11",
    //   zoom: 18,
    //   center: [127.0648, 37.5102],
    //   pitch: 60,
    //   antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased
    // });
    // map.on("style.load", () => {
    //   console.log("map on!");
    // });
  }, []);
  return (
    <Canvas
      shadows
      frameloop="demand"
      camera={{
        fov: 25,
        // lookAt(vector) {
        //   console.log(vector);
        //   return vector;
        // },
        // position: center,
        position: [100, 100, 100],
      }}
    >
      <React.Suspense fallback={null}>
        <group>
          <primitive
            position={[10, -2, 3]}
            // position={center}
            scale={[1, 1, 1]}
            object={gltf.scene}
          ></primitive>
          <primitive object={new THREE.AxesHelper(100)} />
        </group>

        {/* <Environment
          preset="forest"
          background
          far={100}
          ground={{ height: 0, scale: 200 }}
          // ground={{ height: 0, scale: 200 }}
        /> */}
        <OrbitControls />
        <ambientLight intensity={0.2} />
        <pointLight
          power={50}
          position={[0, 100, 6]}
          castShadow={true}
          shadow-mapSize-width={2500}
          shadow-mapSize-height={2500}
        />
      </React.Suspense>
    </Canvas>
  );
}
