import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import React from "react";
import { TypedTypePolicies } from "generated/apollo-helper";
import { store } from "store";

const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      allNode: {
        async read() {
          const { store } = await import("store");
          const {
            model: { properties },
          } = store.getState();
          return properties;
        },
      },
      getNodesById: {
        read(_, { args }) {
          if (!args) throw new Error("No args");
          const {
            model: { properties },
          } = store.getState();
          const result = properties.filter((item) => item.id === args.id);
          return result;
        },
      },
      getNodesByField: {
        read(_, { args }) {
          if (!args) throw new Error("No args");
          const {
            model: { properties },
          } = store.getState();
          const { field, value } = args;
          const result = properties.filter(
            (item) => item[field as keyof typeof item] === value,
          );
          return result;
        },
      },
    },
  },
};

const cache = new InMemoryCache({
  typePolicies,
});

export const client = new ApolloClient({
  cache,
  // connectToDevTools: true,
  // resolvers,
});

export function ApolloClientProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

// const resolvers: Resolvers = {
//   Query
// };
