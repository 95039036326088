import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCsvThunk, setDbIdThunk } from "./thunks";
import Papa from "papaparse";
import { isEmpty, chain, get, find } from "lodash";

export * from "./thunks";

export enum PropertyKey {
  BuildingName = "BuildingName",
  MaterialCategory = "MaterialCategory",
  MaterialSubcategory = "MaterialSubcategory",
  Facility = "Facility",
  Building = "Building",
  Floortype = "Floortype",
  FloorNum = "FloorNum",
  FloorName = "FloorName",
  Zone = "Zone",
  Member = "Member",
  MemberName = "MemberName",
  MaterialName = "MaterialName",
  CoarseAggregate = "CoarseAggregate",
  Strength = "Strength",
  Slump = "Slump",
  ItemName = "ItemName",
  ItemStandard = "ItemStandard",
  Result = "Result",
  ObjectIds = "ObjectIds",
}

type GroupedPropertyType = {
  [key in PropertyKey]: string;
};

type IdAndDbId = {
  id: string;
  dbId?: number;
};

export type PropertyType = Omit<GroupedPropertyType, "ObjectIds"> & IdAndDbId;

export type SelectedPropertyType = Omit<GroupedPropertyType, "ObjectIds"> & {
  id: string;
  dbId: number;
};

export interface ModelState {
  loading: boolean;
  rawCsv: string | null;
  parsedCsv: Papa.ParseResult<unknown> | null;
  objectIdsGroupedProperties: GroupedPropertyType[];
  properties: PropertyType[];
  dbProperties: SelectedPropertyType[];
  floorName: string[];
  foundIdAndDbId: IdAndDbId[];
  aggregateSelectedProperties: SelectedPropertyType[];
  tableSelectedProperties: SelectedPropertyType[];
  currentSelectedId: string[];
}

const initialState: ModelState = {
  loading: false,
  rawCsv: null,
  parsedCsv: null,
  objectIdsGroupedProperties: [],
  properties: [],
  dbProperties: [],
  floorName: [],
  foundIdAndDbId: [],
  aggregateSelectedProperties: [],
  tableSelectedProperties: [],
  currentSelectedId: [],
};

// type Data = {
//   name: string | number;
//   dbId: number;
//   children?: Data[];
//   fragIds?: number[];
// };

export const modelSlice = createSlice({
  name: "glTF-csv",
  initialState,
  reducers: {
    parseCsv(state) {
      if (state.rawCsv) {
        Papa.parse(state.rawCsv, {
          header: true,
          complete(results) {
            if (isEmpty(results.data)) return;
            const parsedData: PropertyType[] = [];
            (results.data as GroupedPropertyType[]).map((item) => {
              const { ObjectIds, ...rest } = item;
              if (!ObjectIds) return null;
              const splittedIds = ObjectIds.split(":");
              if (splittedIds.length < 2) {
                parsedData.push({ id: ObjectIds, ...rest });
              } else {
                splittedIds.map((splittedId) => {
                  parsedData.push({ id: splittedId, ...rest });
                  return null;
                });
              }
              state.properties = parsedData;
              return null;
            });

            const floorName = chain(parsedData)
              .groupBy("FloorName")
              .map((value) => get(find(value, "FloorName"), "FloorName"))
              .value();
            state.floorName = floorName as unknown as string[];
            state.parsedCsv = results;
          },
        });
      }
    },
    getNodesByField(
      state,
      action: PayloadAction<{ field: string; value: string }>,
    ) {
      const { dbProperties } = state;
      const { field, value } = action.payload;
      const result = dbProperties.filter(
        (item) => item[field as keyof typeof item] === value,
      );
      state.aggregateSelectedProperties = result;
      state.tableSelectedProperties = [];
    },
    getTableSelectByIds(state, action: PayloadAction<string[]>) {
      const ids = action.payload;
      const result = ids.reduce((pre, cur) => {
        return [
          ...pre,
          ...state.dbProperties.filter((item) => item.id === cur),
        ];
      }, [] as SelectedPropertyType[]);
      state.tableSelectedProperties = result;
    },
    setCurrentSelectedByDbId(state, action: PayloadAction<number[]>) {
      const dbIds = action.payload;
      const ids = dbIds.reduce((pre, cur) => {
        return [
          ...pre,
          ...state.dbProperties
            .filter((item) => item.dbId === cur)
            .map((item) => item.id),
        ];
      }, [] as string[]);
      state.currentSelectedId = ids;
      const tableSelectedProperties = ids.reduce((pre, cur) => {
        return [
          ...pre,
          ...state.dbProperties.filter((item) => item.id === cur),
        ];
      }, [] as SelectedPropertyType[]);
      state.tableSelectedProperties = tableSelectedProperties;
    },
    setCurrentSelectedById(state, action: PayloadAction<string[]>) {
      const ids = action.payload;
      if (!isEmpty(ids)) {
        const tableSelectedProperties = ids.reduce((pre, cur) => {
          return [
            ...pre,
            ...state.dbProperties.filter((item) => item.id === cur),
          ];
        }, [] as SelectedPropertyType[]);
        state.tableSelectedProperties = tableSelectedProperties;
      } else {
        state.tableSelectedProperties = [];
      }
      state.currentSelectedId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCsvThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCsvThunk.fulfilled, (state, action) => {
        const rawCsv = action.payload;
        state.loading = false;
        state.rawCsv = rawCsv;
      })
      .addCase(setDbIdThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(setDbIdThunk.fulfilled, (state, action) => {
        const dbProperties = action.payload;
        state.loading = false;
        state.dbProperties = dbProperties;
      })
      .addCase(setDbIdThunk.rejected, (response) => {
        console.log(response);
      });
  },
});

export const {
  parseCsv,
  getNodesByField,
  getTableSelectByIds,
  setCurrentSelectedById,
  setCurrentSelectedByDbId,
} = modelSlice.actions;
