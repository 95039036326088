export interface Colors {
  white: string;
  black: string;
  primary: ColorSystem;
  secondary: ColorSystem;
  success: ColorSystem;
  warning: ColorSystem;
  error: ColorSystem;
  alert: Pick<Depth, "_80">;
  grey: Omit<Depth, "_05">;
  purple: Omit<Depth, "_30" | "_50" | "_70">;
  shadow: Pick<Depth, "_10">;
}
export interface Alert {
  red: string;
}
export interface Grayscale {
  offWhite: string;
  bg: string;
  input: string;
  line: string;
  placehold: string;
  label: string;
  body: string;
  ash: string;
  offBlack: string;
  dark: string;
}
export interface ColorSystem {
  bg: string;
  light: string;
  darkmode: string;
  default: string;
  dark: string;
}
export interface Depth {
  _05: string;
  _10: string;
  _20: string;
  _30: string;
  _40: string;
  _50: string;
  _60: string;
  _70: string;
  _80: string;
  _90: string;
  _100: string;
}

export const colors: Colors = {
  white: "#fff",
  black: "#000",
  primary: {
    bg: "#EBECFE",
    light: "#BFBEFC",
    darkmode: "#A996FF",
    default: "#610BEF",
    dark: "#4700AB",
  },
  secondary: {
    bg: "#8DE9FF",
    light: "#E3FEFF",
    darkmode: "#50C8FC",
    default: "#005BD4",
    dark: "#0041AC",
  },
  success: {
    bg: "#CBFFAE",
    light: "#EAF9DE",
    darkmode: "#A6F787",
    default: "#008A00",
    dark: "#067306",
  },
  warning: {
    bg: "#FFE6B0",
    light: "#FFF8E9",
    darkmode: "#FFDF9A",
    default: "#EAAC30",
    dark: "#946300",
  },
  error: {
    bg: "#FFABE8",
    light: "#FFECFC",
    darkmode: "#FF75CB",
    default: "#CA024F",
    dark: "#9E0038",
  },
  alert: {
    _80: "#FF475D",
  },
  grey: {
    _10: "#F2F2F2",
    _20: "#D4D3D7",
    _30: "#BEBDC3",
    _40: "#A8A7AF",
    _50: "#93919C",
    _60: "#7D7B88",
    _70: "#676574",
    _80: "#514F60",
    _90: "#3C394C",
    _100: "#262338",
  },
  shadow: {
    _10: "#807F7F",
  },
  purple: {
    _05: "#EBE7FD",
    _10: "#B4A7F1 ",
    _20: "#A291EE",
    _40: "#836DE9",
    _60: "#664AE3",
    _80: "#513BB5",
    _90: "#3C2C87",
    _100: "#342574",
  },
};
