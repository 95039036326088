import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { customTheme } from "./Theme";
export * from "./Theme";

export function BuilderhubThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <ThemeProvider theme={customTheme}>{children}</ThemeProvider>;
}
